@use "design-system" as ds;
$theme: core !default;

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  line-height: 1.35;
  font-feature-settings: "kern", "liga", "pnum", "onum";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  @include ds.background(secondary, $theme: $theme);
  margin: 0;
  padding: 0;
  @include ds.text-color(primary, $theme: $theme);
  overflow: auto;

  &.no-scroll {
    overflow: hidden;
  }
}

iframe {
  border: 0px;
}

.content-area {
  height: calc(100vh - ds.$header-height--smallscreen);
  height: calc(100svh - ds.$header-height--smallscreen);
  padding-top: ds.$header-height--smallscreen;

  // The header is slightly larger on omni ekonomi mobile
  @if $theme == ekonomi {
    height: calc(100vh - 102px);
    height: calc(100svh - 102px);
    padding-top: 102px;
  }

  @media (min-width: ds.$screen-size--small) {
    padding-top: ds.$header-height--largescreen;
    height: calc(100vh - ds.$header-height--largescreen);
    height: calc(100svh - ds.$header-height--largescreen);
  }
}

.page {
  margin: 0 auto ds.spacing() auto;
}

.section {
  padding-top: ds.spacing(base);
}

.layout {
  @media (min-width: ds.$screen-size--small) {
    display: flex;
    flex-direction: row;
    gap: ds.spacing();
    justify-content: center;
  }

  &--primary-content {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;

    @media (min-width: ds.$screen-size--small) {
      max-width: ds.$content-width--max;
    }
  }
}

// Base Styles
figure {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

#settingClipPath {
  clip-path: path("M0 0h16v16H0z");
}
